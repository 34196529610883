import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import { TenantSiteContext } from './tenant-site-context';
import { InstanceService } from '../instance/instance.service';


@Injectable()
export class TenantDataService {
  constructor(private instanceService: InstanceService,
    private httpClient: HttpClient
  ) { }

  getTenants(): Observable<TenantSiteContext[]> {
    return this.httpClient.get<TenantSiteContext[]>(this.buildUrl('api/tenant/GetAllTenants'));
  }

  saveTenant(tenant: TenantSiteContext): Observable<TenantSiteContext> {
    return this.httpClient.post<TenantSiteContext>(this.buildUrl('api/tenant/SaveTenant'), tenant);
  }

  populateSeedData(tenantId: string): Observable<TenantSiteContext> {
    return this.httpClient.post<TenantSiteContext>(this.buildUrl('api/tenant/SeedTenant?tenantId='+tenantId), null);
  }

  populateSampleData(tenantId: string): Observable<TenantSiteContext> {
    return this.httpClient.post<TenantSiteContext>(this.buildUrl('api/tenant/PopulateSampleData?tenantId='+tenantId), null);
  }

  copySampleSite(tenantId: string): Observable<TenantSiteContext> {
    return this.httpClient.post<TenantSiteContext>(this.buildUrl('api/tenant/CopySampleSite?tenantId=' + tenantId), null);
  }

  removeSampleData(tenantId: string): Observable<TenantSiteContext> {
    return this.httpClient.post<TenantSiteContext>(this.buildUrl('api/tenant/RemoveSampleData?tenantId='+tenantId), null);
  }

  getNoviAdminAuthCode(tenantId: string): Observable<string> {
    return this.httpClient.post<string>(this.buildUrl('account/GenerateNoviAdminAuthCode?tenantId='+tenantId), null);
  }

  private buildUrl(apiPath): string {
    return this.instanceService.currentInstance.value.RootUrl + apiPath;
  }
}
