import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingIndicatorService } from '../loading.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css']
})
export class LoadingIndicatorComponent implements OnInit {

  show : boolean = false;
  private subscription:Subscription;
  constructor(private loadingIndicatorService: LoadingIndicatorService) { 
    
  }

  ngOnInit() {
    this.subscription = this.loadingIndicatorService.currentState
    .subscribe(newState => { 
      this.show = newState
    });
  }

}
