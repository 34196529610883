export class TenantSiteContext {
  TenantID: string;
  Name: string;
  Domain: string;
  CssUrl: string;
  PageViewMapDefinition: string;
  Offline: boolean;

  constructor() {};


}
