import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { JobDataService } from '../job-data.service';
import { InstanceService } from '../../instance/instance.service';

import {JobHeartbeat} from '../job-heartbeat';

@Component({
  selector: 'mc-heartbeat-list',
  templateUrl: './heartbeat-list.component.html',
  styleUrls: ['./heartbeat-list.component.css']
})
export class HeartbeatListComponent implements OnInit {
  jobHeartbeats: JobHeartbeat[];
  oneDayAgo: Date;

  constructor(private jobDataService : JobDataService, private instanceService : InstanceService) { 

  }

  ngOnInit() {
    this.oneDayAgo = new Date();
    this.oneDayAgo.setDate( this.oneDayAgo.getDate() - 1 );
    this.instanceService.currentInstance.subscribe(instance => this.loadJobHeartbeats());
  }

  loadJobHeartbeats() {
    this.jobDataService.getJobHeartbeats().subscribe(
      jobHeartbeats => this.jobHeartbeats = jobHeartbeats);
  }

}
