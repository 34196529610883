import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { Queue } from '../queue';
import { JobDataService } from '../job-data.service';
import { InstanceService } from 'app/instance/instance.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./queue-list.component.css']
})
export class QueueListComponent implements OnInit {
  queues$: Observable<Queue[]>;
  selectedSourceQueue: Queue = null;
  selectedTargetQueue: Queue = null;
  numberOfMessages: number;

  constructor(private jobDataService : JobDataService, 
    private instanceService : InstanceService,
    private toastrService : ToastrService) { 

  }

  ngOnInit() {
    this.queues$ = this.jobDataService.getAllQueues();
    this.instanceService.currentInstance.subscribe(instance => this.loadQueueList());
   }
 
  loadQueueList() {
     this.jobDataService.getAllQueues(true);
  }

  moveQueueMessagesClicked() {
    if (this.selectedSourceQueue == null || this.selectedTargetQueue == null || !this.numberOfMessages) {
      this.toastrService.warning ("Please validate your entries");
      return;
    }

    this.jobDataService.moveQueueMessages(this.selectedSourceQueue.Name, this.selectedTargetQueue.Name, this.numberOfMessages).subscribe(tenant => {
       this.toastrService.success("Message move complete.");
       this.loadQueueList();
      });
  }

  retryPoisonQueueMessages(poisonQueueName: string, numberOfMessages: number) {

    var sourceQueueName = poisonQueueName;
    var targetQueueName = poisonQueueName.substring(0, poisonQueueName.lastIndexOf("-poison"));
    this.jobDataService.moveQueueMessages(sourceQueueName, targetQueueName, numberOfMessages).subscribe(tenant => { 
      this.toastrService.success("Poison queue messages sent for retry.");
      this.loadQueueList();
    });    
  }

}
