
<div class="row">
   <div class="col-xs-6">
    <input type="text" class="form-control" placeholder="Search (name, key, description)" #searchBox (keyup)="0" />
  </div>
  <!-- <div class="col-xs-6">
    <button class="btn btn-success pull-right" (click)="onAddTenantClick()"><i class="glyphicon glyphicon-plus"></i> Add Tenant</button>
  </div>-->
</div>
<div class="list-group">
  <div class="list-group-item" 
    *ngFor="let featureFlag of featureFlags | featureFlagFilter : searchBox.value">
    <div class="row">
      <div class="c-feature-flag-list-item col-xs-6">
        <a class="c-feature-flag-list-item_name" [routerLink]="['/feature', featureFlag.FeatureKey]">{{featureFlag.Name}}</a> <br/>
        <span class="c-feature-flag-list-item_description">{{featureFlag.Description}}</span>
      </div>
      <div class="col-xs-4">
        <span class="c-feature-flag-list-item_key">{{featureFlag.FeatureKey}}</span>
      </div>
      <div class="col-xs-2">
          <ng-switchery [(ngModel)]="featureFlag.FeatureActive" (ngModelChange)="onToggleChange(featureFlag.FeatureKey, featureFlag.FeatureActive)" color="switchery-success" sticked="true"></ng-switchery>
      </div>
    </div>
  </div>
</div>
<ul>
</ul>