import { Component, OnInit } from '@angular/core';

import { TenantSiteContext } from '../../tenant/tenant-site-context';
import { TenantDataService } from '../../tenant/tenant-data.service';
import { JobDataService } from '../job-data.service';
import { InstanceService } from '../../instance/instance.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-job-home',
  templateUrl: './job-home.component.html',
  styleUrls: ['./job-home.component.css']
})
export class JobHomeComponent implements OnInit {

  readonly AllTenants: TenantSiteContext = {
    TenantID: "",
    Name: "All Tenants",
    Domain: "",
    CssUrl: "",
    PageViewMapDefinition: "",
    Offline: false
  }

  tenants: TenantSiteContext[];
  tasks: string[];
  selectedTenant: TenantSiteContext = null;
  selectedTask: string = null;

  constructor(private tenantDataService: TenantDataService,
    private jobDataService: JobDataService,
    private instanceService: InstanceService,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.instanceService.currentInstance.subscribe(instance => this.loadTasks());
    this.instanceService.currentInstance.subscribe(instance => this.loadTenants());
  }

  loadTenants() {
    this.tenantDataService.getTenants().subscribe(
      tenants => {
        this.tenants = tenants;
        this.tenants.push(this.AllTenants);
      }
    )
  }

  loadTasks() {
    this.jobDataService.getAvailableBackgroundTasks().subscribe(
      tasks => this.tasks = tasks
    )
  }

  queueTask() {
    if (this.selectedTenant == null)
      this.toastrService.warning("Please select a tenant!");
    if (this.selectedTask == null)
      this.toastrService.warning("Please select a task!");

    if (this.selectedTenant.Name == "All Tenants") {
      if (confirm("I confirm that I want to run this job for all tenants.")) {
        this.jobDataService.queueBackgroundTaskForAllTenants(this.selectedTask)
          .subscribe(() => this.toastrService.success("Task queued for all tenants"));
      }
    } else {
      this.jobDataService.queueBackgroundTask(this.selectedTenant.TenantID, this.selectedTask)
        .subscribe(() => this.toastrService.success("Task queued"));
    }
  }

}
