<h2>Queue Tenant Background Task</h2>
<div class="form-inline">
  <div class="form-group">
    <select [(ngModel)]="selectedTenant" class="form-control" >
      <option [ngValue]="null">Select tenant...</option>
      <option *ngFor="let tenant of tenants" [ngValue]="tenant">
        {{tenant.Name}}
      </option>
    </select>
  </div>
  <div class="form-group">
    <select [(ngModel)]="selectedTask" class="form-control" >
      <option [ngValue]="null">Select task...</option>
      <option *ngFor="let task of tasks" [ngValue]="task">
        {{task}}
      </option>
    </select>
  </div>
  <div class="form-group">
    <button type="button" class="btn btn-success" (click)="queueTask()"><i class="glyphicon glyphicon-play"></i> Queue Task</button>
  </div>
</div>
<mc-heartbeat-list></mc-heartbeat-list>
<mc-queue-list></mc-queue-list>