import { Injectable } from '@angular/core';
import { NoviInstance } from './novi-instance';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()

export class InstanceService {


  allInstances = [
    new NoviInstance("Local", "https://localhost:44300/"),
    new NoviInstance("Testing", "https://app-novi-test-east-001.azurewebsites.net/"),
    new NoviInstance("Production", "https://noviprod1east.azurewebsites.net/"),
    new NoviInstance("Production-002", "https://app-novi-prod-east-002.azurewebsites.net/"),
    new NoviInstance("Staging", "https://app-novi-onboarding-001.azurewebsites.net/")
  ];
  public currentInstance = new BehaviorSubject<NoviInstance>(this.allInstances[1]);

  // get currentInstance() {
  //     return this._currentInstance.asObservable().share();
  // }

  constructor() {

  }


  public SetCurrentInstance(newInstance: NoviInstance) {
    this.currentInstance.next(newInstance);
  }
}
