import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { bindCallback, Observable, throwError } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';
import { LoadingIndicatorService } from './shared/loading.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private loadingIndicatorService: LoadingIndicatorService,
        private toastrService: ToastrService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingIndicatorService.show();
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    }
                    
                    this.toastrService.error(errorMessage);
                    this.loadingIndicatorService.hide();
                    return throwError(errorMessage);
                }),
                tap(e => {
                    if (e instanceof HttpResponse) {
                        this.loadingIndicatorService.hide();
                    }
                    return e;
                })
            )
    }
}