import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LoadingIndicatorService } from './loading.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [LoadingIndicatorComponent],
  providers: [LoadingIndicatorService],
  exports: [LoadingIndicatorComponent]
})
export class SharedModule { }
