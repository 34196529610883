<nav class="navbar navbar-novi">
  <div class="container">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a href="#">
        <span class="fas fa-space-shuttle fa-2x fa-rotate-270 fa-space-shuttle_logo"></span>
        <img class="navbar-logo" src="assets/noviams-mc-logo2.png">
      </a>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav">
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/sites']">
            <span class='fas fa-sitemap'></span> Sites
          </a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/features']">
            <span class='fas fa-sliders-h'></span> Feature Flags
          </a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/jobs']">
            <span class='fas fa-heartbeat'></span> Jobs & Health
          </a>
        </li>
        
      </ul>
        <div class="navbar-right navbar-form">
          <div class="form-inline">
            <div class="form-group">
              <label>Novi Environment</label>
              <select [(ngModel)]="selectedInstance" class="form-control" (change)="onInstanceChange($event)" >
                <option *ngFor="let instance of instances" [ngValue]="instance">
                  {{instance.Name}}
                </option>
              </select>
            </div>
            <button class="btn btn-primary" (click)="logout()"><i class="glyphicon glyphicon-log-out"></i> Logout</button>
          </div>
        </div>
    </div><!-- /.navbar-collapse -->
  </div><!-- /.container -->
</nav>
<div class="container">
    <app-loading-indicator></app-loading-indicator>
    <router-outlet></router-outlet>
</div>

<div class="footer">
    <div class="container">
  <img class="footer-logo" src="assets/noviams-mc-logo2.png">
  <p class="copyright">
    "Waco, we have a problem" <br/>
    Copyright 2019 Novi AMS</p>
    </div>
</div>

