import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlag } from './feature-flag';
import { InstanceService } from '../instance/instance.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class FeatureFlagDataService {
  constructor(private httpClient: HttpClient,
    private instanceService: InstanceService
  ) { }

  getFeatureFlag(key: string): Observable<FeatureFlag> {
    return this.httpClient.get<FeatureFlag>(this.buildUrl('api/featureflag/GetFeatureFlag?key=' + key));
  }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.httpClient.get<FeatureFlag[]>(this.buildUrl('api/featureflag/GetAllFeatureFlags'));
  }

  toggleFeature(key: string, active: boolean): Observable<null> {
    return this.httpClient.post<null>(this.buildUrl('api/featureflag/ToggleFeatureActive?key='+ key + '&active=' + active), null);
  }

  saveFeature(flag: FeatureFlag): Observable<null> {
    return this.httpClient.post<null>(this.buildUrl('api/featureflag/Save'), flag);
  }

  private buildUrl(apiPath): string {
    return this.instanceService.currentInstance.value.RootUrl + apiPath;
  }
}
