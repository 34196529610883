import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlag } from '../feature-flag';
@Pipe({
  name: 'featureFlagFilter'
})
export class FeatureFlagListFilterPipe implements PipeTransform {
  transform(items: FeatureFlag[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
return items.filter( it => {
      return it.Name.toLowerCase().includes(searchText) || it.FeatureKey.toLowerCase().includes(searchText) || it.Description.toLowerCase().includes(searchText);
    });
   }
}