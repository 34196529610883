import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { NgJsonEditorModule } from 'ang-jsoneditor' 
import { TenantModule } from './tenant/tenant.module';
import { FeatureFlagModule } from './feature-flag/feature-flag.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { SharedModule } from './shared/shared.module';
import { JobModule } from './jobs/job.module';
import { AppComponent } from './app.component';
import { InstanceService } from './instance/instance.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { PublicClientApplication, BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel } from '@azure/msal-browser';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ToastrModule } from 'ngx-toastr';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'd2574002-4665-4004-8c64-2783bee06f64',
      authority: 'https://login.microsoftonline.com/ae9cb7e7-dbbd-4181-905c-bb3a7f5fecc0',
      redirectUri: window.location.origin,
      postLogoutRedirectUri: ''
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://localhost:44300', ['https://traffic2revenue.com/2ddce4a8-3e08-4b56-8204-4f5d669f5fd0/user_impersonation']);
  protectedResourceMap.set('https://app-novi-test-east-001.azurewebsites.net', ['https://traffic2revenue.com/2ddce4a8-3e08-4b56-8204-4f5d669f5fd0/user_impersonation']);
  protectedResourceMap.set('https://noviprod1east.azurewebsites.net', ['https://traffic2revenue.com/f8535f55-fe1f-48e6-b7d9-f5bdebe7c27a/user_impersonation']);
  protectedResourceMap.set('https://app-novi-prod-east-002.azurewebsites.net/', ['https://traffic2revenue.com/f8535f55-fe1f-48e6-b7d9-f5bdebe7c27a/user_impersonation']);
  protectedResourceMap.set('https://app-novi-prod-east-003.azurewebsites.net/', ['https://traffic2revenue.com/f8535f55-fe1f-48e6-b7d9-f5bdebe7c27a/user_impersonation']);
  protectedResourceMap.set('https://app-novi-onboarding-001.azurewebsites.net', ['https://traffic2revenue.com/f8535f55-fe1f-48e6-b7d9-f5bdebe7c27a/user_impersonation']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/home'
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TenantModule,
    FeatureFlagModule,
    JobModule,
    FormsModule,
    SharedModule,
    NgJsonEditorModule,
    HttpClientModule,
    MsalModule,
    ToastrModule.forRoot()
  ],
  providers: [
    InstanceService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
