import { Component } from '@angular/core';
import { NoviInstance } from './instance/novi-instance'
import { InstanceService } from './instance/instance.service'
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'mc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Mission Control';
  instances: NoviInstance[];
  selectedInstance: NoviInstance;

  ngOnInit(): void {
    this.instances = this.instanceService.allInstances;
    this.instanceService.currentInstance.subscribe(instance => this.selectedInstance = instance);
  }

  constructor(
    private instanceService: InstanceService,
    private msalService : MsalService
  ) {
   
  }

  onInstanceChange($event) {
    this.instanceService.SetCurrentInstance(this.selectedInstance);
  }

  logout() {
    this.msalService.logout();
  }
}
