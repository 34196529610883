import {Router, ActivatedRoute, Params} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TenantSiteContext } from '../tenant-site-context'
import { TenantDataService } from '../tenant-data.service'
import { InstanceService } from '../../instance/instance.service'

@Component({
  selector: 'app-novi-login',
  templateUrl: './novi-login.component.html',
  styleUrls: ['./novi-login.component.css']
})
export class NoviLoginComponent implements OnInit {

  public error : string;

  constructor(private route: ActivatedRoute, 
    private instanceService: InstanceService,
    private tenantDataService: TenantDataService,
    private router : Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const tenantId = params['tenantid'];
      const environment = params['environment'];
      const returnUrl = params['returnurl'];
      const loginRoot = params['loginroot'];
      
      const instanceForEnvironment = this.instanceService.allInstances.find(i => i.Name.toLowerCase() == environment.toLowerCase());
      
      if (!instanceForEnvironment) {
        this.error = "Cannot find environment. Please double check the request.";
        return;
      }
      
      this.instanceService.SetCurrentInstance(instanceForEnvironment);

      this.tenantDataService.getTenants().subscribe(allTenants => {
        const matchingTenant = allTenants.find(t => t.TenantID.toLowerCase() == tenantId.toLowerCase());

        if (!matchingTenant) {
          this.error = "Cannot find tenant. Please double check the request.";
          return;
        }

        this.tenantDataService.getNoviAdminAuthCode(matchingTenant.TenantID).subscribe(code => {

          //We use the specified tenantroot here instead of parsing it ourselves from the tenant domain because we might have a cookie override
          window.location.href = loginRoot + "/account/noviadminlogin?code=" + code + "&returnurl=" + returnUrl;
        });
      });
    });
  }

}
