<h2>
  <span>Heartbeats</span>
  <button class="btn btn-info pull-right" (click)="loadJobHeartbeats()" type="button"><i class="glyphicon glyphicon-refresh"></i> Refresh</button>
</h2>
<table class="table table-bordered table-hover">
  <thead>
    <tr>
      <th>Job Name</th>
      <th>Machine Name</th>
      <th>Last Run Time</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let heartbeat of jobHeartbeats">
      <td>{{heartbeat.JobName}}</td>
      <td>{{heartbeat.MachineName}}</td>
      <td [ngClass]="{danger: heartbeat.LastRunTimeUTC <= oneDayAgo}">
        {{heartbeat.LastRunTimeUTC | date:'short'}}
        <span *ngIf="heartbeat.LastRunTimeUTC <= oneDayAgo" class="pull-right text-danger glyphicon glyphicon-exclamation-sign"></span>
      </td>
    </tr>
  </tbody>
</table>