import { Component, OnInit } from '@angular/core';
import { TenantSiteContext } from '../tenant-site-context'
import { TenantDataService } from '../tenant-data.service'
import { InstanceService } from '../../instance/instance.service'
import { NoviInstance } from 'app/instance/novi-instance';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'mc-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.css']
})
export class TenantListComponent implements OnInit {
  tenants: TenantSiteContext[];
  selectedTenant: TenantSiteContext;
  instance : NoviInstance;

  constructor(private tenantDataService: TenantDataService,
  private instanceService: InstanceService,
  private cookieService: CookieService) { }

  ngOnInit() {
    //this.loadTenants();

    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
      this.loadTenants()
    });

    this.cookieService.set("mc-been-there", "true", 7, undefined, undefined, true, "None");
  }

  onTenantClick(tenant) {
    this.selectedTenant = tenant;
  }

  onTenantLoginClick(tenant: TenantSiteContext) {
    var safariWindow = window.open("", "_blank");
    this.tenantDataService.getNoviAdminAuthCode(tenant.TenantID).subscribe(code => {
      var url = "https://" + tenant.Domain + "/account/noviadminlogin?code=" + code;
      safariWindow.location.href = url;
    });
  }

  onTenantVisitClick(tenant: TenantSiteContext) {
    window.open("https://" + tenant.Domain, "_blank");
  }

  onAddTenantClick() {
    this.selectedTenant = new TenantSiteContext();
  }

  onTenantSave() {
    this.loadTenants();
    this.selectedTenant = null;
  }

  onTenantDetailCancel() {
    this.selectedTenant = null;
  }

  private loadTenants() {
    this.tenantDataService.getTenants().subscribe(
      tenants => this.tenants = tenants);
  }
}
