<form *ngIf="editingFeatureFlag$ | async as flag" (ngSubmit)="onSubmit(flag)">
  <div class="row c-feature_flag-detail-header">
    <div class="col-md-10">
        <h3>{{ flag.Name }}</h3>
        <div class="c-feature-flag-detail-header_key">{{ flag.FeatureKey }}</div>
        
    </div>
    <div class="col-md-2">
        <ng-switchery class="c-feature-flag-detail-header_toggle" 
        [(ngModel)]="flag.FeatureActive" 
        color="switchery-success" 
        sticked="true"
        size="switchery-lg"
        name="tglFeatureActive"
        ></ng-switchery>
    </div>
  </div>
  
  <div class="row">
    <div class="col-xs-12">
      <div class="form-group">
          <label for="inputTenantName">Limit to Specific Tenants</label>
           <ng-select [items]="tenants$ | async"
           bindLabel="Name"
           bindValue="TenantID"
           [multiple]="true"
           [(ngModel)]="flag.LimitToTenantIds"
           name="specificTenantsSelect"></ng-select>
           
        </div>
        <div class="form-group">
          <label for="inputFlagDescription">Description</label>
          <textarea  [ngModel]="flag.Description" class="form-control" id="inputFlagDescription" name="inputFlagDescription" rows="3" disabled></textarea>
        </div>
        <button type="button" class="btn btn-success" type="submit">Save Feature Flag</button>  
  </div>
</div>
</form>