import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FeatureFlag } from '../feature-flag'
import { FeatureFlagDataService } from '../feature-flag-data.service'
import { InstanceService } from '../../instance/instance.service'
import { TenantSiteContext } from '../../tenant/tenant-site-context';
import { TenantDataService } from '../../tenant/tenant-data.service';
import { ToastrIconClasses, ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-feature-flag-detail',
  templateUrl: './feature-flag-detail.component.html',
  styleUrls: ['./feature-flag-detail.component.css']
})
export class FeatureFlagDetailComponent implements OnInit {

  editingFeatureFlag$: Observable<FeatureFlag>;
  tenants$: Observable<TenantSiteContext[]>;
  selectedTenants: TenantSiteContext[];
  tenantConfig: any = {
    highlight: false,
    create: false,
    persist: true,
    labelField: 'Name',
    valueField: 'TenantId',
    searchField: ['Name', 'TenantId']
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureFlagDataService: FeatureFlagDataService,
    private instanceService: InstanceService,
    private tenantDataService : TenantDataService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.editingFeatureFlag$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.featureFlagDataService.getFeatureFlag(params.get('key')))
    );
    this.tenants$ = this.tenantDataService.getTenants();

    this.instanceService.currentInstance.subscribe(instance => this.loadTenants());
  }
  
  loadTenants() {
    
  }

  onSubmit(flag: FeatureFlag) {
    this.featureFlagDataService.saveFeature(flag).subscribe(tenant => this.toastrService.success("Feature flag save complete."));;
      
  }

}
