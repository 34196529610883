import { Pipe, PipeTransform } from '@angular/core';
import { TenantSiteContext } from '../tenant-site-context';
@Pipe({
  name: 'tenantFilter'
})
export class TenantListFilterPipe implements PipeTransform {
  transform(items: TenantSiteContext[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
return items.filter( it => {
      return it.Name.toLowerCase().includes(searchText) || it.TenantID.toLowerCase().includes(searchText);
    });
   }
}