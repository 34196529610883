import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { HeartbeatListComponent } from './heartbeat-list/heartbeat-list.component';
import { JobDataService } from './job-data.service';
import { JobHomeComponent } from './job-home/job-home.component';
import { QueueListComponent } from './queue-list/queue-list.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [HeartbeatListComponent, JobHomeComponent, QueueListComponent],
  providers: [JobDataService]
})
export class JobModule { }
