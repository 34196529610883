import { Component, OnInit } from '@angular/core';
import { FeatureFlag } from '../feature-flag'
import { FeatureFlagDataService } from '../feature-flag-data.service'
import { InstanceService } from '../../instance/instance.service'

@Component({
  selector: 'mc-feature-flag-list',
  templateUrl: './feature-flag-list.component.html',
  styleUrls: ['./feature-flag-list.component.css']
})
export class FeatureFlagListComponent implements OnInit {
  featureFlags: FeatureFlag[];

  constructor(private featureFlagDataService: FeatureFlagDataService,
  private instanceService: InstanceService) { }

  ngOnInit() {
    this.instanceService.currentInstance.subscribe(instance => this.loadFeatureFlags());
  }

  private loadFeatureFlags() {
    this.featureFlagDataService.getFeatureFlags().subscribe(
      featureFlags => this.featureFlags = featureFlags);
  }

  public onToggleChange(key: string, active: boolean) {
    this.featureFlagDataService.toggleFeature(key, active).subscribe();
  }
}
