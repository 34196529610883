<div class="search-bar row">
  <div class="col-xs-6">
    <input type="text" class="form-control" placeholder="Search (name or abbreviation)" #searchBox (keyup)="0" />
  </div>
  <div class="col-xs-6">
    <button class="btn btn-success pull-right" (click)="onAddTenantClick()"><i class="glyphicon glyphicon-plus"></i> Add
      Site</button>
  </div>
</div>
<table *ngIf="!selectedTenant" class="table table-striped">
  <thead>
    <tr>
      <th scope="col" class="hidden-xs">Abbreviation</th>
      <th scope="col">Name</th>
      <th scope="col" class="hidden-xs">Domain</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>

    <tr *ngFor="let tenant of tenants | tenantFilter : searchBox.value" [ngClass]="{ active: tenant == selectedTenant }">
      <td class="hidden-xs">{{tenant.TenantID}}</td>
      <td>{{tenant.Name}}</td>
      <td class="hidden-xs">{{tenant.Domain}}</td>
      <td>
        <a class="tenant-actions btn btn-info" (click)="onTenantLoginClick(tenant)">
          <span class="glyphicon glyphicon-log-in"></span> Login
        </a>
        <a class="tenant-actions btn btn-primary hidden-xs" (click)="onTenantClick(tenant)">
          <span class="glyphicon glyphicon-edit"></span> Edit
        </a>
        <a class="tenant-actions btn btn-default hidden-xs" (click)="onTenantVisitClick(tenant)">
          <span class="fas fa-globe"></span> Visit
        </a>
      </td>
    </tr>
  </tbody>
</table>

<mc-tenant-detail [tenant]="selectedTenant" (tenantSave)="onTenantSave()" (cancel)="onTenantDetailCancel()"></mc-tenant-detail>
