import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, KeyValueDiffers } from '@angular/core';
import * as _ from 'lodash';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

import { TenantSiteContext } from '../tenant-site-context'
import { TenantDataService } from '../tenant-data.service'
import { ToastrService } from 'ngx-toastr';
//import { KeyValueDiffers } from '@angular/core/src/change_detection/differs/keyvalue_differs';

@Component({
  selector: 'mc-tenant-detail',
  templateUrl: './tenant-detail.component.html',
  styleUrls: ['./tenant-detail.component.css']
})
export class TenantDetailComponent implements OnInit, OnChanges {
  public editorOptions: JsonEditorOptions;
  public data: any;
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;

  @Input() tenant: TenantSiteContext;
  editingTenant: TenantSiteContext;

  @Output() tenantSave = new EventEmitter<TenantSiteContext>();
  @Output() cancel = new EventEmitter();

  private _differ: any;

  constructor(private tenantDataService: TenantDataService, 
    private toastrService: ToastrService,
    private differs: KeyValueDiffers) {
    this._differ = differs.find({}).create();
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
    this.data = {};
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tenant) {
      this.editingTenant = _.cloneDeep(changes.tenant.currentValue);
      this.data = changes.tenant.currentValue && changes.tenant.currentValue.PageViewMapDefinition ? JSON.parse( changes.tenant.currentValue.PageViewMapDefinition) : {};
    }
  }

  ngDoCheck(){
    const changes = this._differ.diff(this.editingTenant);
    
    if(changes && changes.isDirty && this.editingTenant && this.editingTenant.TenantID){
      this.editingTenant.TenantID = this.editingTenant.TenantID.toLowerCase();
    }
  }

  onSaveClick() {
    const validationMsg = this.validateTenant(this.editingTenant);
    if (validationMsg.length > 0) {
      this.toastrService.warning(validationMsg);
      return;
    }
    this.editingTenant.PageViewMapDefinition = this.editor.getText();
    this.tenantDataService.saveTenant(this.editingTenant)
      .subscribe(tenant => {
          this.tenantSave.emit(tenant);
          this.toastrService.success("Tenant saved successfully.");
      });
  }

  onSeedDataClick() {
    if (!this.editingTenant.TenantID)
      return;

    this.tenantDataService.populateSeedData(this.editingTenant.TenantID)
      .subscribe(tenant => this.toastrService.success("Seed data population complete."));
  }

  onPopulateSampleDataClick() {
    if (!this.editingTenant.TenantID)
      return;

    this.tenantDataService.populateSampleData(this.editingTenant.TenantID)
      .subscribe(tenant => this.toastrService.success("Sample data population complete."));
  }

  onCopySampleSite() {
    if (!this.editingTenant.TenantID)
      return;

    this.tenantDataService.copySampleSite(this.editingTenant.TenantID)
      .subscribe(tenant => this.toastrService.success("Sample site copying queued."));
  }

  onRemoveSampleDataClick() {
    if (!this.editingTenant.TenantID)
      return;

    this.tenantDataService.removeSampleData(this.editingTenant.TenantID)
      .subscribe(tenant => this.toastrService.success("Sample data removal complete."));
  }

  onCancelClick() {
    this.tenant = null;
    this.cancel.emit();
  }

  private validateTenant(tenant: TenantSiteContext): string   {
    let result = '';

    if (!tenant.TenantID || tenant.TenantID.length === 0) {
      result += 'Please enter a tenant id.';
    }
    if (!tenant.Name || tenant.Name.length === 0) {
      result += 'Please enter a tenant name.';
    }

    return result;
  }

}
