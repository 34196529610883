
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { InstanceService } from '../instance/instance.service';
import { JobHeartbeat } from './job-heartbeat';
import { Queue } from './queue';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class JobDataService {

  private getAllQueuesSubject: Subject<Queue[]>;
  private getAllQueuesRequest: Observable<Queue[]>;

  constructor(private httpClient: HttpClient,
    private instanceService: InstanceService
  ) { 
    this.getAllQueuesSubject = new ReplaySubject(1);
  }

  getJobHeartbeats(): Observable<JobHeartbeat[]> {
    return this.httpClient.get<JobHeartbeat[]>(this.buildUrl('api/job/GetJobHeartbeats')).pipe(map(hbArray => { 
      return hbArray.map(hb => { 
        hb.LastRunTimeUTC = new Date(hb.LastRunTimeUTC);
        return hb;
      });
    }));
  }

  getAvailableBackgroundTasks(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.buildUrl('api/job/GetAvailableBackgroundTasks'));
  }

  queueBackgroundTask(tenantId: string, taskName: string): Observable<null> {
    return this.httpClient.post<null>(this.buildUrl('api/job/QueueBackgroundTask?tenantId='+tenantId+'&taskName='+taskName), null);
  } 

  queueBackgroundTaskForAllTenants(taskName: string): Observable<null> {
    return this.httpClient.post<null>(this.buildUrl('api/job/QueueBackgroundTaskForAllTenants?taskName='+taskName), null);
  } 

  getAllQueues(refresh: boolean = false): Observable<Queue[]> {
    if (refresh || !this.getAllQueuesRequest) {
      this.getAllQueuesRequest = this.httpClient.post<Queue[]>(this.buildUrl('api/job/GetAllQueues'), null);

      this.getAllQueuesRequest.subscribe(
        result => this.getAllQueuesSubject.next(result),
        err => this.getAllQueuesSubject.error(err)
      );
    }

    return this.getAllQueuesSubject.asObservable();
  }  
  moveQueueMessages(sourceQueueName: string, targetQueueName: string, numberOfMessages : number): Observable<null> {
    return this.httpClient.post<null>(this.buildUrl('api/job/MoveQueueMessages?sourceQueueName='+sourceQueueName+'&targetQueueName='+targetQueueName+'&numberOfMessages='+numberOfMessages), null);
  }   

    private buildUrl(apiPath): string {
    return this.instanceService.currentInstance.value.RootUrl + apiPath;
  }
}
