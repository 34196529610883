//Core
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//Third Party
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { NgSelectModule } from '@ng-select/ng-select';

//Novi MC
import { FeatureFlagListComponent } from './feature-flag-list/feature-flag-list.component';
import { FeatureFlagDataService } from './feature-flag-data.service'
import { FeatureFlagListFilterPipe } from './feature-flag-list/feature-flag-list-filter.component';
import { FeatureFlagDetailComponent } from './feature-flag-detail/feature-flag-detail.component';
import { MsalGuard } from '@azure/msal-angular';

const featureRoutes: Routes = [
  { path: 'features',  component: FeatureFlagListComponent, canActivate: [MsalGuard] },
  { path: 'feature/:key', component: FeatureFlagDetailComponent, canActivate: [MsalGuard] }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSwitcheryModule,
    NgSelectModule,
    RouterModule.forChild(featureRoutes)
  ],
  exports: [RouterModule],
  declarations: [FeatureFlagListComponent, FeatureFlagListFilterPipe, FeatureFlagDetailComponent],
  providers: [FeatureFlagDataService]
})
export class FeatureFlagModule { }
