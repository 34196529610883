<div *ngIf="tenant">
  <div class="form-group">
    <label for="inputTenantName">Abbreviation (Tenant ID) <span *ngIf="!tenant.TenantID">(max 8 characters)</span></label>
    <span *ngIf="tenant.TenantID">{{tenant.TenantID}}</span>
    <input *ngIf="!tenant.TenantID" [(ngModel)]="editingTenant.TenantID" [maxlength]="8" class="form-control">
  </div>
  <div class="form-group">
    <label for="inputTenantName">Name</label>
    <input [(ngModel)]="editingTenant.Name" class="form-control" id="inputTenantName" />
    <p class="help-block">Reminder: Should match the name of the customer in <a href="https://hq.noviams.com">Novi HQ</a>.</p>
  </div>
  <div class="form-group">
    <label for="inputDomainName">Domain Name</label>
    <input [(ngModel)]="editingTenant.Domain" class="form-control" id="inputDomainName" />
  </div>
  <div class="form-group">
    <label for="inputCssUrl">CSS Url</label>
    <input [(ngModel)]="editingTenant.CssUrl" class="form-control" id="inputCssUrl" />
  </div>
  <div class="form-group">
    <label for="inputPageViewMapDefinition">Template Settings</label>
    <json-editor [data]="data" [options]="editorOptions"></json-editor>
  </div>
  <div class="form-group" *ngIf="tenant.TenantID">
    <label>Seed Data</label> <i>(Data will only be inserted if it doesn't already exist. You shouldn't run this if this tenant is for a 1.5 migration.)</i><br />
    <button class="btn btn-info" (click)="onSeedDataClick()" type="button">Populate Seed Data</button>
  </div>
  <div class="form-group" *ngIf="tenant.TenantID">
    <label>Sample Data</label><br />
    <button class="btn btn-info" (click)="onPopulateSampleDataClick()" type="button">Populate Sample Data</button>
    <!-- <button class="btn btn-danger" (click)="onRemoveSampleDataClick()" type="button">Remove Sample Data</button> -->
  </div>
  <div class="form-group" *ngIf="tenant.TenantID">
    <label>Sample Site</label><br />
    <button class="btn btn-info" (click)="onCopySampleSite()" type="button">Copy Sample Site</button>
  </div>
  <div class="checkbox">
    <label><input type="checkbox" [(ngModel)]="editingTenant.Offline">Offline</label>
  </div>
  <button class="btn btn-primary" (click)="onSaveClick()">Save</button>
  <button class="btn btn-warning" (click)="onCancelClick()">Cancel</button>
</div>
