<h2>
  <span>Queues</span>
  <button class="btn btn-info pull-right" (click)="loadQueueList()" type="button"><i
      class="glyphicon glyphicon-refresh"></i> Refresh</button>
</h2>
<table class="table table-bordered table-hover">
  <thead>
    <tr>
      <th>Queue Name</th>
      <th>Message Count</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let queue of (queues$ | async)">
      <td>{{queue.Name}}</td>
      <td [ngClass]="{danger: queue.MessageCount >= 10}">
        {{queue.MessageCount}}
        <span *ngIf="queue.MessageCount >= 10"
          class="pull-right text-danger glyphicon glyphicon-exclamation-sign"></span>
      </td>
      <td>
        <div *ngIf="queue.MessageCount > 0 && queue.Name.endsWith('poison')">
          <a (click)="!!retryPoisonQueueMessages(queue.Name, 10)" href>Retry 10</a> | <a
            (click)="!!retryPoisonQueueMessages(queue.Name, queue.MessageCount)" href>Retry All</a>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div class="form-inline">
  <div class="form-group">
    <select [(ngModel)]="selectedSourceQueue" class="form-control">
      <option [ngValue]="null">Select source queue...</option>
      <option *ngFor="let queue of (queues$ | async)" [ngValue]="queue">
        {{queue.Name}}
      </option>
    </select>
  </div>
  <div class="form-group">
    <select [(ngModel)]="selectedTargetQueue" class="form-control">
      <option [ngValue]="null">Select target queue...</option>
      <option *ngFor="let queue of (queues$ | async)" [ngValue]="queue">
        {{queue.Name}}
      </option>
    </select>
  </div>
  <div class="form-group">
    <input [(ngModel)]="numberOfMessages" class="form-control" placeholder="# of Messages">
  </div>
  <div class="form-group">
    <button type="button" class="btn btn-success" (click)="moveQueueMessagesClicked()"><i
        class="glyphicon glyphicon-transfer"></i> Move Queue Messages</button>
  </div>
</div>
