import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantListComponent } from '../tenant/tenant-list/tenant-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { JobHomeComponent } from '../jobs/job-home/job-home.component';
import { NoviLoginComponent } from '../tenant/novi-login/novi-login.component';
//import { FeatureFlagListComponent} from '../feature-flag/feature-flag-list/feature-flag-list.component'
const routes: Routes = [
  {
    path: 'sites',
    component: TenantListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    redirectTo: 'sites',
    pathMatch: 'full'
  },
  {
    path: 'jobs',
    component: JobHomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'novi-login',
    component: NoviLoginComponent,
    canActivate: [MsalGuard]
  }
  // {
  //   path: 'features',
  //   component: FeatureFlagListComponent,
  //   canActivate: [AuthenticationGuard]
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }