import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TenantListComponent } from './tenant-list/tenant-list.component';
import { TenantDataService } from './tenant-data.service'
import { TenantDetailComponent } from './tenant-detail/tenant-detail.component';
import { TenantListFilterPipe } from './tenant-list/tenant-list-filter.component';
import { NoviLoginComponent } from './novi-login/novi-login.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgJsonEditorModule
  ],
  exports: [TenantListComponent],
  declarations: [TenantListComponent, TenantDetailComponent, TenantListFilterPipe, NoviLoginComponent],
  providers: [TenantDataService]
})
export class TenantModule { }
